import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { from, space, titlepiece42 } from '@guardian/source/foundations';
import { formatAmount } from 'helpers/forms/checkouts';
import { currencies, spokenCurrencies, } from 'helpers/internationalisation/currency';
const supCss = css `
	font-size: 60%;
	vertical-align: 9px;
	${from.tablet} {
		font-size: 60%;
		vertical-align: 14px;
	}
`;
function Monthly({ isoCurrency, amount, promotion, name }) {
    if (promotion) {
        const promotionPrice = promotion.discountedPrice ?? amount;
        const promotionDuration = promotion.discount?.durationMonths ?? 0;
        return (_jsxs(_Fragment, { children: [_jsxs("h1", { css: headerTitleText, children: ["Thank you ", _jsx("span", { "data-qm-masking": "blocklist", children: name }), "for supporting us with", ' ', _jsx(YellowHighlight, { amount: promotionPrice, currency: isoCurrency }), `/month`, _jsx("sup", { css: supCss, children: "*" })] }), _jsxs("p", { css: css `
						margin-top: ${space[2]}px;
					`, children: [_jsx("sup", { children: "*" }), " You'll pay", ' ', formatAmount(currencies[isoCurrency], spokenCurrencies[isoCurrency], promotionPrice, false), "/month for the first ", promotionDuration, " months, then", ' ', formatAmount(currencies[isoCurrency], spokenCurrencies[isoCurrency], amount, false), "/month afterwards unless you cancel."] })] }));
    }
    return (_jsxs("h1", { css: headerTitleText, children: ["Thank you ", _jsx("span", { "data-qm-masking": "blocklist", children: name }), "for supporting us with ", _jsx(YellowHighlight, { currency: isoCurrency, amount: amount }), " each month \u2764\uFE0F"] }));
}
function Annual({ isoCurrency, amount, promotion, name }) {
    if (promotion) {
        const promotionPrice = promotion.discountedPrice ?? amount;
        return (_jsxs(_Fragment, { children: [_jsxs("h1", { css: headerTitleText, children: ["Thank you ", _jsx("span", { "data-qm-masking": "blocklist", children: name }), "for supporting us with", ' ', _jsx(YellowHighlight, { amount: promotionPrice, currency: isoCurrency }), `/year`, _jsx("sup", { css: supCss, children: "*" })] }), _jsxs("p", { css: css `
						margin-top: ${space[2]}px;
					`, children: [_jsx("sup", { children: "*" }), " You'll pay", ' ', formatAmount(currencies[isoCurrency], spokenCurrencies[isoCurrency], promotionPrice, false), "/year for the first year, then", ' ', formatAmount(currencies[isoCurrency], spokenCurrencies[isoCurrency], amount, false), "/year afterwards unless you cancel."] })] }));
    }
    return (_jsxs("h1", { css: headerTitleText, children: ["Thank you ", _jsx("span", { "data-qm-masking": "blocklist", children: name }), "for supporting us with ", _jsx(YellowHighlight, { currency: isoCurrency, amount: amount }), " each year \u2764\uFE0F"] }));
}
const tier3lineBreak = css `
	display: none;
	${from.tablet} {
		display: inline-block;
	}
`;
const yellowAmountText = css `
	background-color: #ffe500;
	padding: 0 5px;
`;
function YellowHighlight({ amount, currency, productName }) {
    return (_jsxs("span", { css: yellowAmountText, children: [!productName &&
                formatAmount(currencies[currency], spokenCurrencies[currency], amount, false), productName && productName] }));
}
const headerTitleText = css `
	${titlepiece42};
	font-size: 24px;
	${from.tablet} {
		font-size: 40px;
	}
`;
const tier3HeaderTitleText = css `
	${titlepiece42};
	font-size: 24px;
	${from.tablet} {
		font-size: 28px;
	}
`;
function Heading({ name, isOneOffPayPal, isTier3, amount, currency, contributionType, promotion, }) {
    const maybeNameAndTrailingSpace = name && name.length < 10 ? `${name} ` : '';
    // Do not show special header to paypal/one-off as we don't have the relevant info after the redirect
    if (isOneOffPayPal || !amount) {
        return (_jsxs("h1", { css: headerTitleText, children: ["Thank you", ' ', _jsx("span", { "data-qm-masking": "blocklist", children: maybeNameAndTrailingSpace }), "for your valuable contribution"] }));
    }
    if (isTier3) {
        return (_jsxs("h1", { css: tier3HeaderTitleText, children: ["Thank you", ' ', _jsx("span", { "data-qm-masking": "blocklist", children: maybeNameAndTrailingSpace }), "for subscribing to", ' ', _jsx(YellowHighlight, { currency: currency, amount: amount, productName: 'Digital + print.' }), _jsx("br", { css: tier3lineBreak }), "Your valued support powers our journalism."] }));
    }
    switch (contributionType) {
        case 'ONE_OFF':
            return (_jsxs("h1", { css: headerTitleText, children: ["Thank you for supporting us today with", ' ', _jsx(YellowHighlight, { currency: currency, amount: amount }), " \u2764\uFE0F"] }));
        case 'MONTHLY':
            return (_jsx(Monthly, { amount: amount, isoCurrency: currency, promotion: promotion, name: maybeNameAndTrailingSpace }));
        case 'ANNUAL':
            return (_jsx(Annual, { amount: amount, isoCurrency: currency, promotion: promotion, name: maybeNameAndTrailingSpace }));
        default:
            return (_jsxs("h1", { css: headerTitleText, children: ["Thank you", ' ', _jsx("span", { "data-qm-masking": "blocklist", children: maybeNameAndTrailingSpace }), "for your valuable contribution"] }));
    }
}
export default Heading;
