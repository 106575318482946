import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { from, neutral, space, textSans15 } from '@guardian/source/foundations';
import { Button, Link, Radio, RadioGroup, SvgArrowRightStraight, } from '@guardian/source/react-components';
import { privacyLink } from 'helpers/legal';
import { OPHAN_COMPONENT_ID_SET_REMINDER } from 'helpers/thankYouPages/utils/ophan';
import { trackComponentClick } from 'helpers/tracking/behaviour';
import { createOneOffReminderEndpoint, createRecurringReminderEndpoint, } from 'helpers/urls/routes';
import { isCodeOrProd } from 'helpers/urls/url';
import { logException } from 'helpers/utilities/logger';
import { catchPromiseHandler } from 'helpers/utilities/promise';
const form = css `
	margin-top: ${space[5]}px;

	& > * + * {
		margin-top: ${space[5]}px;
	}
`;
const hideBeforeTablet = css `
	display: none;

	${from.tablet} {
		display: block;
	}
`;
const privacyText = css `
	${hideBeforeTablet}
	${textSans15}
  font-family: GuardianTextSans,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif !important;
	font-size: 15px !important;

	color: ${neutral[20]};
	margin-top: ${space[3]}px;
`;
const privacyTextLink = css `
	${textSans15};
	color: ${neutral[20]};
`;
const REMINDER_PLATFORM = 'SUPPORT';
const REMINDER_STAGE = 'POST';
const REMINDER_COMPONENT = 'THANKYOU';
const getReminderPeriod = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // javascript dates run from 0-11, we want 1-12
    const paddedMonth = month.toString().padStart(2, '0');
    return `${year}-${paddedMonth}-01`;
};
const getReminderOption = (monthsUntilDate) => `${monthsUntilDate}-months`;
const getDefaultLabel = (date, monthsUntilDate, now) => {
    const month = date.toLocaleDateString('default', {
        month: 'long',
    });
    const year = now.getFullYear() === date.getFullYear() ? '' : ` ${date.getFullYear()}`;
    return `in ${monthsUntilDate} months only (${month}${year})`;
};
const getRecurringReminderChoice = () => {
    const now = new Date();
    const startMonth = now.getMonth() + 1;
    const date = new Date(now.getFullYear(), startMonth);
    const month = date.toLocaleDateString('default', {
        month: 'long',
    });
    return {
        type: 'RECURRING',
        label: `Once each month (from ${month})`,
        signup: {
            reminderFrequencyMonths: 1,
        },
    };
};
const getOneOffReminderChoiceWithDefaultLabel = (monthsUntilDate) => {
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth() + monthsUntilDate);
    return {
        type: 'ONE_OFF',
        label: getDefaultLabel(date, monthsUntilDate, now),
        signup: {
            reminderPeriod: getReminderPeriod(date),
            reminderOption: getReminderOption(monthsUntilDate),
        },
    };
};
const getDefaultReminderChoices = () => [
    getRecurringReminderChoice(),
    getOneOffReminderChoiceWithDefaultLabel(3),
    getOneOffReminderChoiceWithDefaultLabel(6),
    getOneOffReminderChoiceWithDefaultLabel(9),
];
const getReminderUrl = (choice) => {
    if (choice.type === 'ONE_OFF') {
        return createOneOffReminderEndpoint;
    }
    return createRecurringReminderEndpoint;
};
const reminderChoices = getDefaultReminderChoices();
export function SupportReminderBodyCopy({ supportReminderState, onChange, }) {
    return (_jsx(_Fragment, { children: supportReminderState.hasBeenCompleted ? (_jsx("p", { children: "We will be in touch at the time you selected, so look out for a message from the Guardian in your inbox." })) : (_jsxs(_Fragment, { children: [_jsx("p", { children: "Many readers choose to support Guardian journalism at various points in the year. Opt in to whichever time suits you best, and we\u2019ll send you a maximum of two reminder emails, with no obligation to give." }), _jsx("form", { css: form, children: _jsx(RadioGroup, { name: "reminder", label: "I'd like to be reminded in:", children: reminderChoices.map((choice, index) => (_jsx(Radio, { value: choice.label, label: choice.label, checked: supportReminderState.selectedChoiceIndex === index, onChange: () => {
                                onChange?.(index);
                            } }))) }) })] })) }));
}
export function SupportReminderCTAandPrivacy({ email, supportReminderState, onClick, }) {
    const setReminder = () => {
        const choice = reminderChoices[supportReminderState.selectedChoiceIndex];
        const url = getReminderUrl(choice);
        if (!isCodeOrProd()) {
            return;
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                reminderPlatform: REMINDER_PLATFORM,
                reminderComponent: REMINDER_COMPONENT,
                reminderStage: REMINDER_STAGE,
                ...choice.signup,
            }),
        })
            .then((response) => {
            if (!response.ok) {
                logException('Reminder sign up failed at the point of request');
            }
        })
            .catch(catchPromiseHandler('Error creating reminder sign up'));
    };
    const onSubmit = () => {
        if (email) {
            setReminder();
            trackComponentClick(OPHAN_COMPONENT_ID_SET_REMINDER);
            onClick?.();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(Button, { onClick: onSubmit, priority: "primary", size: "default", icon: _jsx(SvgArrowRightStraight, {}), iconSide: "right", nudgeIcon: true, children: "Set my reminder" }) }), _jsxs("p", { css: privacyText, children: ["To find out what personal data we collect and how we use it, please visit our", ' ', _jsx(Link, { cssOverrides: privacyTextLink, href: privacyLink, target: "_blank", rel: "noopener noreferrer", priority: "secondary", children: "Privacy Policy" }), "."] })] }));
}
