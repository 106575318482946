import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { error, neutral, space, textSans20, } from '@guardian/source/foundations';
import { isTestUser } from 'helpers/user/user';
import { ThankYouUserTypeSelector } from './thankYouUserTypeSelector';
const testUserBannerStyles = css `
	${textSans20};
	background-color: ${error[400]};
	color: ${neutral[100]};
	text-align: center;
	padding: ${space[2]}px 0;
	font-weight: bold;
`;
export function TestUserBanner() {
    const testUser = isTestUser();
    if (testUser) {
        const isThankYouPage = window.location.pathname.includes('thankyou');
        return (_jsxs("div", { css: testUserBannerStyles, children: [_jsx("p", { children: "You are a test user" }), isThankYouPage && _jsx(ThankYouUserTypeSelector, {})] }));
    }
    return null;
}
