import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css, ThemeProvider } from '@emotion/react';
import { from, palette, space, textSans15, textSansBold15, textSansBold24, until, } from '@guardian/source/foundations';
import { buttonThemeReaderRevenueBrand, LinkButton, } from '@guardian/source/react-components';
import { BenefitsCheckList } from 'components/checkoutBenefits/benefitsCheckList';
import { simpleFormatAmount } from 'helpers/forms/checkouts';
import { currencies } from 'helpers/internationalisation/currency';
import { filterBenefitByABTest, filterBenefitByRegion, } from 'helpers/productCatalog';
import { recurringContributionPeriodMap } from 'helpers/utilities/timePeriods';
import { ThreeTierLozenge } from './threeTierLozenge';
const container = (isRecommended, isUserSelected, subdueHighlight) => {
    let cardOrder = 2;
    if (isRecommended) {
        cardOrder = 1;
    }
    else if (isUserSelected) {
        cardOrder = 0;
    }
    return css `
		position: ${isRecommended || isUserSelected ? 'relative' : 'static'};
		background-color: ${(isRecommended && !subdueHighlight) || isUserSelected
        ? '#F1FBFF'
        : palette.neutral[100]};
		border-radius: ${space[3]}px;
		padding: 32px ${space[3]}px ${space[6]}px ${space[3]}px;

		${until.desktop} {
			order: ${cardOrder};
			padding-top: ${space[6]}px;
			margin-top: ${isRecommended && subdueHighlight ? '15px' : '0'};
		}
	`;
};
const titleCss = css `
	${textSansBold15};
	color: #606060;
`;
const priceCss = (hasPromotion) => css `
	${textSansBold24};
	position: relative;
	margin-bottom: ${hasPromotion ? '0' : `${space[4]}px`};

	${from.desktop} {
		margin-bottom: ${space[6]}px;
	}
`;
const discountSummaryCss = css `
	display: block;
	font-size: ${space[3]}px;
	font-weight: 400;
	color: #606060;
	margin-bottom: ${space[4]}px;

	${from.desktop} {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		text-align: center;
		margin-bottom: 0;
	}
`;
const previousPriceStrikeThrough = css `
	font-weight: 400;
	text-decoration: line-through;
`;
const btnStyleOverrides = css `
	width: 100%;
	justify-content: center;
	margin-bottom: ${space[6]}px;
`;
const checkmarkBenefitList = css `
	width: 100%;
	text-align: left;

	${from.desktop} {
		width: 90%;
	}
`;
const checkmarkOfferList = css `
	width: 100%;
	text-align: left;
`;
const benefitsPrefixCss = css `
	${textSans15};
	color: ${palette.neutral[7]};
	text-align: left;

	strong {
		font-weight: bold;
	}
`;
const benefitsPrefixPlus = css `
	${textSans15};
	color: #545454; // neutral[38] unavailable
	display: flex;
	align-items: center;
	margin: ${space[3]}px 0;

	:before,
	:after {
		content: '';
		height: 1px;
		background-color: ${palette.neutral[86]};
		flex-grow: 2;
	}

	:before {
		margin-right: ${space[2]}px;
	}

	:after {
		margin-left: ${space[2]}px;
	}
`;
const discountSummaryCopy = (currency, promoCount, price, promotion, paymentFrequency) => {
    /**
     * EXAMPLE:
     * - £6.5/month for 6 months, then £10/month
     * - £173/year for the first year, then £275/year
     */
    const durationMonths = promotion.discount?.durationMonths ?? 0;
    const formattedPrice = simpleFormatAmount(currency, price);
    const formattedPromotionPrice = simpleFormatAmount(currency, promotion.discountedPrice ?? 0);
    const period = paymentFrequency === 'ANNUAL' ? 'year' : 'month';
    const duration = paymentFrequency === 'ANNUAL' ? durationMonths / 12 : durationMonths;
    return `${formattedPromotionPrice}/${period} for ${period === 'year' ? 'the first' : ''}${duration > 1 ? duration : ''} ${period}${duration > 1 ? 's' : ''}, then ${formattedPrice}/${period}${'*'.repeat(promoCount)}`;
};
export function ThreeTierCard({ cardTier, promoCount, isRecommended, isRecommendedSubdued, isUserSelected, currencyId, countryGroupId, paymentFrequency, link, productDescription, price, promotion, ctaCopy, abParticipations, }) {
    const currency = currencies[currencyId];
    const period = recurringContributionPeriodMap[paymentFrequency];
    const formattedPrice = simpleFormatAmount(currency, price);
    const quantumMetricButtonRef = `tier-${cardTier}-button`;
    const { label, benefits, benefitsSummary, offers, offersSummary } = productDescription;
    return (_jsxs("section", { css: container(isRecommended, isUserSelected, isRecommendedSubdued), children: [isUserSelected && _jsx(ThreeTierLozenge, { title: "Your selection" }), isRecommended && !isUserSelected && (_jsx(ThreeTierLozenge, { subdue: isRecommendedSubdued, title: promotion?.landingPage?.roundel ?? 'Highest impact' })), _jsx("h2", { css: titleCss, children: label }), _jsxs("p", { css: priceCss(!!promotion), children: [promotion && (_jsxs(_Fragment, { children: [_jsx("span", { css: previousPriceStrikeThrough, children: formattedPrice }), ' ', `${simpleFormatAmount(currency, promotion.discountedPrice ?? price)}/${period}`, _jsx("span", { css: discountSummaryCss, children: discountSummaryCopy(currency, promoCount, price, promotion, paymentFrequency) })] })), !promotion && `${formattedPrice}/${period}`] }), _jsx(ThemeProvider, { theme: buttonThemeReaderRevenueBrand, children: _jsx(LinkButton, { href: link, cssOverrides: btnStyleOverrides, "data-qm-trackable": quantumMetricButtonRef, children: ctaCopy }) }), benefitsSummary && (_jsx("div", { css: benefitsPrefixCss, children: _jsx("span", { children: benefitsSummary.map((stringPart) => {
                        if (typeof stringPart !== 'string') {
                            return _jsx("strong", { children: stringPart.copy });
                        }
                        else {
                            return stringPart;
                        }
                    }) }) })), offersSummary && (_jsx("div", { css: benefitsPrefixCss, children: _jsx("span", { children: offersSummary.map((stringPart) => {
                        if (typeof stringPart !== 'string') {
                            return _jsx("strong", { children: stringPart.copy });
                        }
                        else {
                            return stringPart;
                        }
                    }) }) })), (benefitsSummary ?? offersSummary) && (_jsx("span", { css: benefitsPrefixPlus, children: "plus" })), _jsx(BenefitsCheckList, { benefitsCheckListData: benefits
                    .filter((benefit) => filterBenefitByRegion(benefit, countryGroupId))
                    .filter((benefit) => filterBenefitByABTest(benefit, abParticipations))
                    .map((benefit) => {
                    return {
                        text: benefit.copy,
                        isChecked: true,
                        toolTip: benefit.tooltip,
                        isNew: benefit.isNew,
                    };
                }), style: 'compact', iconColor: palette.brand[500], cssOverrides: checkmarkBenefitList }), offers && offers.length > 0 && (_jsxs(_Fragment, { children: [_jsx("span", { css: benefitsPrefixPlus, children: "new" }), _jsx(BenefitsCheckList, { benefitsCheckListData: offers.map((offer) => {
                            return {
                                text: offer.copy,
                                isChecked: true,
                                toolTip: offer.tooltip,
                            };
                        }), style: 'hidden', iconColor: palette.brand[500], cssOverrides: checkmarkOfferList })] }))] }));
}
