import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { brand, from, neutral, space, textSansBold20, } from '@guardian/source/foundations';
import { Column, Columns, Hide } from '@guardian/source/react-components';
import { Container } from 'components/layout/container';
const mainStyles = css `
	left: 0;
	right: 0;
	color: ${neutral[100]};
	${from.desktop} {
		position: absolute;
	}
`;
const headingContainerStyle = css `
	> div {
		min-height: 430px;
	}
`;
const headingContainerContent = css `
	padding-top: ${space[2]}px;
	${textSansBold20}
	${from.tablet} {
		margin: auto;
		max-width: 456px;
	}
	${from.desktop} {
		padding-top: ${space[6]}px;
		max-width: 100%;
	}
`;
export function CheckoutHeadingImage(props) {
    return (_jsx("div", { css: mainStyles, children: _jsx(Container, { sideBorders: true, topBorder: props.withTopBorder, borderColor: brand[600], backgroundColor: brand[400], cssOverrides: headingContainerStyle, children: _jsx(Columns, { collapseUntil: "desktop", children: _jsx(Column, { span: [1, 2, 5], children: _jsxs("div", { css: headingContainerContent, children: [props.heading, _jsxs(Hide, { until: "desktop", children: [props.children, props.image] })] }) }) }) }) }));
}
