import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { from, space, textSans15 } from '@guardian/source/foundations';
const footer = css `
	${textSans15};
	margin-top: ${space[2]}px;
	margin-bottom: 28px;

	${from.desktop} {
		margin-bottom: ${space[2]}px;
	}
`;
function ThankYouFooter() {
    return (_jsx("div", { css: footer, children: "If you have any questions about supporting the Guardian, please contact our customer service team." }));
}
export default ThankYouFooter;
