import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { between, from, neutral, space, textEgyptian15, textEgyptian17, textEgyptianBold17, until, } from '@guardian/source/foundations';
import { useEffect } from 'react';
import AppImageGuardianNews from 'components/svgs/appGuardianNews';
import AppImageFeast from 'components/svgs/appImageFeast';
import { trackComponentLoad } from 'helpers/tracking/behaviour';
import AppDownloadImage from './appDownload/AppDownloadImage';
import AppDownloadQRCodes from './appDownload/AppDownloadQRCodes';
import NewspaperArchiveImage from './newspaperArchive/newspaperArchiveImage';
const container = css `
	background: white;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: ${space[2]}px;
	padding-bottom: ${space[5]}px;
	border-bottom: 1px solid ${neutral[86]};

	${from.tablet} {
		max-width: 620px;
		padding-left: ${space[4]}px;
		padding-right: 0px;
		border: 1px solid ${neutral[86]};
	}
`;
const defaultGridContainer = css `
	display: grid;
	grid-column-gap: ${space[3]}px;
	grid-template-columns: min-content 1fr;
	grid-template-areas:
		'icon header'
		'body body';

	${from.tablet} {
		grid-template-areas:
			'icon header'
			'---- body';
	}
`;
const imageryAndQrCodesGridContainer = css `
	display: grid;
	grid-column-gap: ${space[3]}px;
	grid-template-columns: min-content 1fr;
	grid-template-areas:
		'icon header'
		'body body'
		'img img';

	${from.tablet} {
		grid-template-columns: min-content 270px 1fr;
		grid-template-areas:
			'icon header img'
			'---- body img'
			'---- qrCodes qrCodes';
	}

	${between.desktop.and.leftCol} {
		grid-template-areas:
			'icon header header'
			'---- body body'
			'---- qrCodes qrCodes';
	}
`;
const iconContainer = css `
	grid-area: icon;
	display: flex;
	align-self: center;

	svg {
		display: block;
	}

	${from.tablet} {
		display: block;
	}
`;
const headerContainer = css `
	grid-area: header;
	${textEgyptianBold17};
	align-self: center;

	${from.desktop} {
		font-size: 20px;
		margin-top: 2px;
	}
`;
const bodyContainer = css `
	grid-area: body;
`;
const sizeContainer = css `
	z-index: 1;
	${between.tablet.and.desktop} {
		width: 330px;
	}
	${between.leftCol.and.wide} {
		width: 330px;
	}
	${from.wide} {
		width: 350px;
	}
`;
const bodyApps = css `
	display: flex;
	justify-content: space-between;
	margin-top: ${space[6]}px;
`;
const bodyAppsTop = css `
	${from.tablet} {
		border-bottom: 1px solid ${neutral[86]};
	}
`;
const bodyStyle = css `
	max-width: 240px;
	${from.mobileMedium} {
		max-width: 340px;
	}
`;
const bodyCopyStyle = css `
	${textEgyptian15};
	margin-bottom: ${space[1]}px;
	${from.tablet} {
		${textEgyptian17};
	}
`;
const bodyCopyMarginTop = css `
	margin-top: ${space[3]}px;
	${from.tablet} {
		margin-top: ${space[2]}px;
	}
`;
const appContainer = css `
	width: 55px;
	${from.mobileLandscape} {
		width: 75px;
	}
`;
const imgContainer = css `
	grid-area: img;
	align-self: flex-end;
`;
const sizeImgContainer = css `
	margin-top: ${space[2]}px;
	${until.tablet} {
		margin-top: ${space[4]}px;
	}
`;
const qrCodesContainer = css `
	${from.tablet} {
		grid-area: qrCodes;
		margin-top: ${space[5]}px;
		margin-right: ${space[5]}px;
		padding-top: 30px;
		border-top: 1px solid ${neutral[86]};
	}
`;
const paddingRight = css `
	${from.tablet} {
		padding-right: 72px;
	}
`;
const paddingRightApps = css `
	${from.tablet} {
		padding-right: ${space[4]}px;
	}
`;
const imageryPadding = css `
	${until.tablet} {
		padding-bottom: 0;
	}
`;
const hideBelowTablet = css `
	display: none;

	${from.tablet} {
		display: block;
	}
`;
const ctaContainerApps = css `
	margin-top: ${space[4]}px;

	// appDownload ctas require margin-top but appsDownload does not
	> div {
		margin-top: 0;
	}
`;
const ctaTop = css `
	padding-bottom: 32px;
`;
const ctaBottom = css `
	padding-bottom: ${space[4]}px;
`;
function ThankYouModule({ moduleType, isSignedIn, icon, header, bodyCopy, ctas, trackComponentLoadId, bodyCopySecond, ctasSecond, }) {
    useEffect(() => {
        trackComponentLoadId && trackComponentLoad(trackComponentLoadId);
    }, []);
    const hasQrCodes = moduleType === 'appDownload';
    const isDownloadModules = moduleType === 'appsDownload';
    const hasImagery = ['appDownload', 'newspaperArchiveBenefit'].includes(moduleType);
    const gridContainer = hasImagery || hasQrCodes
        ? imageryAndQrCodesGridContainer
        : defaultGridContainer;
    const maybePaddingRight = !hasImagery && (isDownloadModules ? paddingRightApps : paddingRight);
    const isNewspaperArchiveBenefit = moduleType === 'newspaperArchiveBenefit';
    const resizeContainer = isNewspaperArchiveBenefit ? sizeContainer : css ``;
    const resizeImgContainer = !isNewspaperArchiveBenefit
        ? sizeImgContainer
        : css ``;
    const resizeMarginTop = !isNewspaperArchiveBenefit
        ? css `
				margin-top: ${space[6]}px;
		  `
        : css ``;
    return (_jsx("section", { css: [container, maybePaddingRight, hasImagery && imageryPadding], children: _jsxs("div", { css: gridContainer, children: [_jsx("div", { css: iconContainer, children: icon }), _jsx("div", { css: [headerContainer, resizeContainer], children: header }), _jsx("div", { css: [bodyContainer, resizeContainer], children: isDownloadModules ? (_jsxs(_Fragment, { children: [_jsxs("div", { css: [bodyApps, bodyAppsTop], children: [_jsxs("div", { css: bodyStyle, children: [_jsx("p", { css: bodyCopyStyle, children: bodyCopy }), _jsx("div", { css: [ctaContainerApps, ctaTop], children: ctas })] }), _jsx("span", { css: appContainer, children: _jsx(AppImageGuardianNews, {}) })] }), _jsxs("div", { css: bodyApps, children: [_jsxs("div", { css: bodyStyle, children: [_jsx("p", { css: bodyCopyStyle, children: bodyCopySecond }), _jsx("div", { css: [ctaContainerApps, ctaBottom], children: ctasSecond })] }), _jsx("span", { css: appContainer, children: _jsx(AppImageFeast, {}) })] })] })) : (_jsxs(_Fragment, { children: [_jsx("p", { css: [bodyCopyStyle, bodyCopyMarginTop], children: bodyCopy }), _jsx("div", { css: resizeMarginTop, children: ctas })] })) }), hasImagery ? (_jsx("div", { css: [imgContainer, resizeImgContainer], children: isNewspaperArchiveBenefit ? (_jsx(NewspaperArchiveImage, {})) : (_jsx(AppDownloadImage, {})) })) : null, hasQrCodes && isSignedIn ? (_jsx("div", { css: [qrCodesContainer, hideBelowTablet], children: _jsx(AppDownloadQRCodes, {}) })) : null] }) }));
}
export default ThankYouModule;
