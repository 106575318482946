import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { from, space, textEgyptian15, textEgyptian17, } from '@guardian/source/foundations';
import { useState } from 'react';
import { BenefitsCheckList, } from 'components/checkoutBenefits/benefitsCheckList';
import { setThankYouFeedbackSurveyHasBeenCompleted, setThankYouSupportReminder, } from 'helpers/redux/checkout/thankYouState/actions';
import { useContributionsDispatch } from 'helpers/redux/storeHooks';
import { OPHAN_COMPONENT_ID_AUS_MAP, OPHAN_COMPONENT_ID_SIGN_IN, OPHAN_COMPONENT_ID_SIGN_UP, OPHAN_COMPONENT_ID_SOCIAL, OPHAN_COMPONENT_ID_SURVEY, } from 'helpers/thankYouPages/utils/ophan';
import { formatMachineDate, formatUserDate, } from 'helpers/utilities/dateConversions';
import { getWeeklyDays } from 'pages/weekly-subscription-checkout/helpers/deliveryDays';
import AppDownloadBadges, { AppDownloadBadgesEditions, } from './appDownload/AppDownloadBadges';
import { AppDownloadBodyCopy, AppDownloadEditionsBodyCopy, appDownloadEditionsHeader, appDownloadHeader, AppFeastDownloadBodyCopy, appFeastDownloadHeader, AppNewsDownloadBodyCopy, appNewsDownloadHeader, appsDownloadHeader, } from './appDownload/appDownloadItems';
import { ausMapBodyCopy, AusMapCTA, ausMapHeader } from './ausMap/ausMapItems';
import { FeedbackBodyCopy, FeedbackCTA, getFeedbackHeader, } from './feedback/FeedbackItems';
import { SignInBodyCopy, SignInCTA, signInHeader } from './signIn/signInItems';
import { SignUpBodyCopy, signUpHeader } from './signUp/signUpItems';
import { getSocialShareCopy, socialShareHeader, SocialShareIcons, } from './socialShare/SocialShareItems';
import { BenefitsBodyCopy, benefitsHeader, SubscriptionStartBodyCopy, subscriptionStartHeader, } from './subscriptionStart/subscriptionStartItems';
import { SupportReminderBodyCopy, SupportReminderCTAandPrivacy, } from './supportReminder/supportReminderItems';
import { getThankYouModuleIcon } from './thankYouModuleIcons';
const headingCss = css `
	font-weight: 700;
`;
const checklistCss = css `
	margin-top: ${space[4]}px;
	${textEgyptian15}
	${from.tablet} {
		${textEgyptian17}
	}
`;
const defaultSupportReminder = {
    selectedChoiceIndex: 0,
    hasBeenCompleted: false,
    errorMessage: '',
};
const defaultFeedbackSurveyHasBeenCompleted = false;
export const getThankYouModuleData = (countryId, countryGroupId, csrf, isOneOff, amountIsAboveThreshold, email, campaignCode, isTier3, checklistData, supportReminder, feedbackSurveyHasBeenCompleted) => {
    const initialFeedbackSurveyHasBeenCompleted = feedbackSurveyHasBeenCompleted ?? defaultFeedbackSurveyHasBeenCompleted;
    const [feedbackSurveyCompleted, setFeedbackSurveyCompleted] = useState(initialFeedbackSurveyHasBeenCompleted);
    const [supportReminderCompleted, setSupportReminderCompleted] = useState(supportReminder ?? defaultSupportReminder);
    const days = getWeeklyDays();
    const publicationStartDays = days.filter((day) => {
        const invalidPublicationDates = ['-12-24', '-12-25', '-12-30'];
        const date = formatMachineDate(day);
        return !invalidPublicationDates.some((dateSuffix) => date.endsWith(dateSuffix));
    });
    const getFeedbackSurveyLink = (countryId) => {
        const surveyBasePath = 'https://guardiannewsandmedia.formstack.com/forms/';
        if (countryId === 'AU') {
            return `${surveyBasePath}guardian_australia_message`;
        }
        if (isOneOff) {
            return `${surveyBasePath}guardian_supporter_single`;
        }
        return `${surveyBasePath}${amountIsAboveThreshold
            ? 'guardian_supporter_above'
            : 'guardian_supporter_below'}`;
    };
    const thankYouModuleData = {
        appsDownload: {
            icon: getThankYouModuleIcon('appsDownload'),
            header: appsDownloadHeader,
            bodyCopy: (_jsxs(_Fragment, { children: [_jsx("h2", { css: headingCss, children: appNewsDownloadHeader }), _jsx(AppNewsDownloadBodyCopy, {})] })),
            ctas: _jsx(AppDownloadBadges, { countryGroupId: countryGroupId }),
            bodyCopySecond: (_jsxs(_Fragment, { children: [_jsx("h2", { css: headingCss, children: appFeastDownloadHeader }), _jsx(AppFeastDownloadBodyCopy, {})] })),
            ctasSecond: (_jsx(AppDownloadBadges, { countryGroupId: countryGroupId, isFeast: true })),
        },
        appDownload: {
            icon: getThankYouModuleIcon('appDownload'),
            header: appDownloadHeader,
            bodyCopy: _jsx(AppDownloadBodyCopy, {}),
            ctas: _jsx(AppDownloadBadges, { countryGroupId: countryGroupId }),
        },
        appDownloadEditions: {
            icon: getThankYouModuleIcon('appDownload'),
            header: appDownloadEditionsHeader,
            bodyCopy: _jsx(AppDownloadEditionsBodyCopy, {}),
            ctas: _jsx(AppDownloadBadgesEditions, { countryGroupId: countryGroupId }),
        },
        ausMap: {
            icon: getThankYouModuleIcon('ausMap'),
            header: ausMapHeader,
            bodyCopy: ausMapBodyCopy,
            ctas: _jsx(AusMapCTA, {}),
            trackComponentLoadId: OPHAN_COMPONENT_ID_AUS_MAP,
        },
        feedback: {
            icon: getThankYouModuleIcon('feedback'),
            header: getFeedbackHeader(feedbackSurveyCompleted),
            bodyCopy: (_jsx(FeedbackBodyCopy, { feedbackSurveyHasBeenCompleted: feedbackSurveyCompleted })),
            ctas: feedbackSurveyCompleted ? null : (_jsx(FeedbackCTA, { feedbackSurveyLink: getFeedbackSurveyLink(countryId), onClick: () => {
                    setFeedbackSurveyCompleted(true);
                    if (feedbackSurveyHasBeenCompleted) {
                        const dispatch = useContributionsDispatch();
                        dispatch(setThankYouFeedbackSurveyHasBeenCompleted(true));
                    }
                } })),
            trackComponentLoadId: OPHAN_COMPONENT_ID_SURVEY,
        },
        benefits: {
            icon: getThankYouModuleIcon('benefits'),
            header: benefitsHeader,
            bodyCopy: (_jsxs(_Fragment, { children: [_jsx(BenefitsBodyCopy, {}), checklistData && (_jsx(BenefitsCheckList, { benefitsCheckListData: checklistData, cssOverrides: checklistCss }))] })),
            ctas: null,
        },
        newspaperArchiveBenefit: {
            icon: getThankYouModuleIcon('newspaperArchiveBenefit'),
            header: 'The Guardian newspaper archive',
            bodyCopy: (_jsx(_Fragment, { children: "Sign in to start exploring more than 200 years of world history with our newspaper archive. View digital reproductions of every front page, article and advertisement as it was printed in the Guardian from 1821." })),
            ctas: null,
        },
        subscriptionStart: {
            icon: getThankYouModuleIcon('subscriptionStart'),
            header: subscriptionStartHeader,
            bodyCopy: (_jsx(_Fragment, { children: _jsx(SubscriptionStartBodyCopy, { startDateGW: formatUserDate(publicationStartDays[0]) }) })),
            ctas: null,
        },
        signIn: {
            icon: getThankYouModuleIcon('signIn'),
            header: signInHeader(isTier3),
            bodyCopy: _jsx(SignInBodyCopy, { isTier3: isTier3 }),
            ctas: _jsx(SignInCTA, { email: email, csrf: csrf, isTier3: isTier3 }),
            trackComponentLoadId: OPHAN_COMPONENT_ID_SIGN_IN,
        },
        signUp: {
            icon: getThankYouModuleIcon('signUp'),
            header: signUpHeader,
            bodyCopy: _jsx(SignUpBodyCopy, { isTier3: isTier3 }),
            ctas: null,
            trackComponentLoadId: OPHAN_COMPONENT_ID_SIGN_UP,
        },
        socialShare: {
            icon: getThankYouModuleIcon('socialShare'),
            header: socialShareHeader,
            bodyCopy: getSocialShareCopy(countryId),
            ctas: (_jsx(SocialShareIcons, { countryId: countryId, campaignCode: campaignCode })),
            trackComponentLoadId: OPHAN_COMPONENT_ID_SOCIAL,
        },
        supportReminder: {
            icon: getThankYouModuleIcon('supportReminder'),
            header: supportReminderCompleted.hasBeenCompleted
                ? 'Your support reminder is set'
                : 'Set a support reminder',
            bodyCopy: (_jsx(SupportReminderBodyCopy, { supportReminderState: supportReminderCompleted, onChange: (index) => {
                    setSupportReminderCompleted({
                        ...supportReminderCompleted,
                        selectedChoiceIndex: index,
                    });
                    if (supportReminder) {
                        const dispatch = useContributionsDispatch();
                        dispatch(setThankYouSupportReminder({
                            ...supportReminderCompleted,
                            selectedChoiceIndex: index,
                        }));
                    }
                } })),
            ctas: supportReminderCompleted.hasBeenCompleted ? null : (_jsx(SupportReminderCTAandPrivacy, { email: email, supportReminderState: supportReminderCompleted, onClick: () => {
                    setSupportReminderCompleted({
                        ...supportReminderCompleted,
                        hasBeenCompleted: true,
                    });
                    if (supportReminder) {
                        const dispatch = useContributionsDispatch();
                        dispatch(setThankYouSupportReminder({
                            ...supportReminderCompleted,
                            hasBeenCompleted: true,
                        }));
                    }
                } })),
        },
    };
    return thankYouModuleData;
};
