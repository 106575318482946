import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { palette, space } from '@guardian/source/foundations';
import { Column, Columns, Hide } from '@guardian/source/react-components';
import CountryGroupSwitcher from 'components/countryGroupSwitcher/countryGroupSwitcher';
import { Container } from 'components/layout/container';
const switcherContainer = css `
	height: 28px;
	border-left: 1px solid ${palette.brand[600]};
	padding: ${space[2]}px 0 0 ${space[3]}px;
	margin-bottom: ${space[3]}px;
`;
function Nav({ countryGroupIds, selectedCountryGroup, subPath, countryIsAffectedByVATStatus = false, }) {
    return (_jsx(Container, { id: "navigation", element: "nav", sideBorders: true, topBorder: true, borderColor: palette.brand[600], backgroundColor: palette.brand[400], children: _jsx(Hide, { until: "desktop", children: _jsxs(Columns, { children: [_jsx(Column, { span: 5 }), !countryIsAffectedByVATStatus && (_jsx(Column, { children: _jsx("div", { css: switcherContainer, "data-test": "xxx", children: _jsx(CountryGroupSwitcher, { countryGroupIds: countryGroupIds, selectedCountryGroup: selectedCountryGroup, subPath: subPath }) }) }))] }) }) }));
}
export default Nav;
