import { useEffect } from 'react';
import { getValidPaymentMethods } from 'helpers/forms/checkouts';
import { setPaymentMethod } from 'helpers/redux/checkout/payment/paymentMethod/actions';
import { getContributionType } from 'helpers/redux/checkout/product/selectors/productType';
import { useContributionsDispatch, useContributionsSelector, } from 'helpers/redux/storeHooks';
import { trackComponentClick, trackComponentInsert, } from 'helpers/tracking/behaviour';
import { sendEventPaymentMethodSelected } from 'helpers/tracking/quantumMetric';
function PaymentMethodSelectorContainer({ render, contributionTypeOverride, }) {
    const dispatch = useContributionsDispatch();
    const contributionType = contributionTypeOverride ?? useContributionsSelector(getContributionType);
    const { countryId, countryGroupId } = useContributionsSelector((state) => state.common.internationalisation);
    const { name, errors } = useContributionsSelector((state) => state.page.checkoutForm.payment.paymentMethod);
    const availablePaymentMethods = getValidPaymentMethods(contributionType, countryId, countryGroupId);
    function onPaymentMethodEvent(event, paymentMethod) {
        const trackingId = `payment-method-selector-${paymentMethod}`;
        if (event === 'select') {
            trackComponentClick(trackingId);
            sendEventPaymentMethodSelected(paymentMethod);
            dispatch(setPaymentMethod({ paymentMethod }));
        }
        else {
            trackComponentInsert(trackingId);
        }
    }
    useEffect(() => {
        availablePaymentMethods.length === 1 &&
            dispatch(setPaymentMethod({ paymentMethod: availablePaymentMethods[0] }));
    }, []);
    return render({
        availablePaymentMethods: availablePaymentMethods,
        paymentMethod: name,
        validationError: errors?.[0],
        onPaymentMethodEvent,
    });
}
export default PaymentMethodSelectorContainer;
