import { createSlice } from '@reduxjs/toolkit';
import { getWeeklyFulfilmentOption } from 'helpers/productPrice/fulfilmentOptions';
import { paperProductsWithDigital, paperProductsWithoutDigital, } from 'helpers/productPrice/productOptions';
import { GuardianWeekly } from 'helpers/productPrice/subscriptions';
import { setDeliveryCountry } from '../address/actions';
import { resetValidation, validateForm } from '../checkoutActions';
import { isContribution } from './selectors/productType';
import { initialProductState, otherAmountSchema } from './state';
function validateOtherAmount(state) {
    if (!isContribution(state.productType)) {
        return;
    }
    if (state.selectedAmounts[state.productType] === 'other') {
        const validationResult = otherAmountSchema.safeParse(state.otherAmounts[state.productType]);
        if (!validationResult.success) {
            const formattedErrors = validationResult.error.format();
            state.errors = {
                otherAmount: formattedErrors.amount?._errors,
            };
        }
    }
}
export const productSlice = createSlice({
    name: 'product',
    initialState: initialProductState,
    reducers: {
        setProductType(state, action) {
            state.productType = action.payload;
            state.errors.otherAmount = [];
        },
        setProductOption(state, action) {
            state.productOption = action.payload;
        },
        setAddDigital(state, action) {
            state.productOption = action.payload
                ? paperProductsWithDigital[state.productOption]
                : paperProductsWithoutDigital[state.productOption];
        },
        setFulfilmentOption(state, action) {
            state.fulfilmentOption = action.payload;
        },
        setBillingPeriod(state, action) {
            state.billingPeriod = action.payload;
        },
        setProductPrices(state, action) {
            state.productPrices = action.payload;
        },
        setAllAmounts(state, action) {
            state.selectedAmounts = action.payload;
        },
        setSelectedAmount(state, action) {
            const { contributionType, amount } = action.payload;
            const newAmount = amount === 'other' ? amount : Number.parseFloat(amount);
            state.selectedAmounts[contributionType] = newAmount;
        },
        setOtherAmount(state, action) {
            const { contributionType, amount } = action.payload;
            state.otherAmounts[contributionType].amount = amount;
            state.errors.otherAmount = [];
        },
        setCoverTransactionCost(state, action) {
            /* Add 4% to their contribution amount */
            state.coverTransactionCost = action.payload;
        },
        setCurrency(state, action) {
            state.currency = action.payload;
        },
        setOrderIsAGift(state, action) {
            state.orderIsAGift = action.payload;
        },
        setStartDate(state, action) {
            state.startDate = action.payload;
        },
        setOtherAmountError(state, action) {
            if (Array.isArray(state.errors.otherAmount)) {
                state.errors.otherAmount.push(action.payload);
            }
            else {
                state.errors.otherAmount = [action.payload];
            }
        },
        validateOtherAmount,
    },
    extraReducers: (builder) => {
        builder.addCase(setDeliveryCountry, (state, action) => {
            if (state.productType === GuardianWeekly) {
                state.fulfilmentOption = getWeeklyFulfilmentOption(action.payload);
            }
        });
        builder.addCase(validateForm, validateOtherAmount);
        builder.addCase(resetValidation, (state) => {
            state.errors = {};
        });
    },
});
export const productReducer = productSlice.reducer;
