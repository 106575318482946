import { trackComponentEvents } from './trackingOphan';
const trackCheckoutSubmitAttempt = (componentId, eventDetails) => {
    trackComponentEvents({
        component: {
            componentType: 'ACQUISITIONS_BUTTON',
            id: componentId,
            labels: ['checkout-submit'],
        },
        action: 'CLICK',
        value: eventDetails,
    });
};
const trackThankYouPageLoaded = (productCheckout, paymentMethod) => {
    if (typeof trackComponentEvents === 'function') {
        trackComponentEvents({
            component: {
                componentType: 'ACQUISITIONS_OTHER',
                id: 'thank-you-page',
                labels: ['checkout-submit'],
            },
            action: 'VIEW',
            value: `thank-you-page-loaded-${productCheckout}-${paymentMethod ?? ''}`,
        });
    }
};
const trackComponentClick = (componentId) => {
    trackComponentEvents({
        component: {
            componentType: 'ACQUISITIONS_OTHER',
            id: componentId,
        },
        action: 'CLICK',
    });
};
const trackComponentLoad = (componentId) => {
    trackComponentEvents({
        component: {
            componentType: 'ACQUISITIONS_OTHER',
            id: componentId,
        },
        action: 'VIEW',
    });
};
const trackComponentInsert = (componentId) => {
    trackComponentEvents({
        component: {
            componentType: 'ACQUISITIONS_OTHER',
            id: componentId,
        },
        action: 'INSERT',
    });
};
export { trackThankYouPageLoaded, trackComponentClick, trackCheckoutSubmitAttempt, trackComponentLoad, trackComponentInsert, };
