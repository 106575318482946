import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css, ThemeProvider } from '@emotion/react';
import { from, headlineBold24, palette, space, textSans15, until, } from '@guardian/source/foundations';
import { buttonThemeReaderRevenueBrand, LinkButton, } from '@guardian/source/react-components';
import { useState } from 'react';
import { OtherAmount } from '../../../components/otherAmount/otherAmount';
import { PriceCards } from '../../../components/priceCards/priceCards';
import { countryGroups } from '../../../helpers/internationalisation/countryGroup';
import { trackComponentClick } from '../../../helpers/tracking/behaviour';
import { PaymentCards } from './PaymentIcons';
const sectionStyle = css `
	max-width: 600px;
	margin: auto;
	background-color: ${palette.neutral[100]};
	border-radius: ${space[3]}px;
	padding: 32px;
	${until.desktop} {
		padding-top: ${space[6]}px;
	}
`;
const titleStyle = css `
	margin: 0 0 ${space[2]}px;
	text-align: center;
	${headlineBold24}
	${from.tablet} {
		font-size: 28px;
	}
`;
const standFirst = css `
	color: #606060;
	margin-bottom: ${space[2]}px;
	${from.desktop} {
		margin-bottom: ${space[3]}px;
	}
`;
const btnStyleOverrides = css `
	width: 100%;
	justify-content: center;
	margin-bottom: ${space[3]}px;
	margin-top: ${space[5]}px;
`;
const buttonContainer = css `
	display: flex;
	flex-direction: column;
`;
export function OneOffCard({ currencyGlyph, countryGroupId, amounts, currencyId, heading, }) {
    const oneOffAmounts = amounts.amountsCardData.ONE_OFF;
    const [selectedAmount, setSelectedAmount] = useState(oneOffAmounts.defaultAmount);
    const [otherAmount, setOtherAmount] = useState('');
    return (_jsxs("section", { css: sectionStyle, children: [_jsxs("div", { css: css `
					${textSans15}
				`, children: [_jsx("h2", { css: titleStyle, children: heading ?? 'Support just once' }), _jsxs("p", { css: standFirst, children: ["We welcome support of any size, any time, whether you choose to give", ' ', currencyGlyph, "1 or much more."] }), _jsx(PriceCards, { amounts: oneOffAmounts.amounts, selectedAmount: selectedAmount, currency: currencyId, 
                        // This is always undefined as we're ONE_OFF
                        paymentInterval: undefined, onAmountChange: (amount) => {
                            if (amount === 'other') {
                                setSelectedAmount(amount);
                            }
                            else {
                                const amountNumber = parseInt(amount, 10);
                                if (!isNaN(amountNumber)) {
                                    setSelectedAmount(amountNumber);
                                }
                            }
                        }, hideChooseYourAmount: oneOffAmounts.hideChooseYourAmount, otherAmountField: _jsx(OtherAmount, { currency: currencyId, selectedAmount: selectedAmount, otherAmount: otherAmount, onOtherAmountChange: (otherAmount) => {
                                setOtherAmount(otherAmount);
                            }, errors: [] }) })] }), _jsxs("div", { css: buttonContainer, children: [_jsx(ThemeProvider, { theme: buttonThemeReaderRevenueBrand, children: _jsx(LinkButton, { href: `/${countryGroups[countryGroupId].supportInternationalisationId}/contribute/checkout?selected-contribution-type=one_off&selected-amount=${selectedAmount === 'other' ? otherAmount : selectedAmount}`, cssOverrides: btnStyleOverrides, onClick: () => {
                                trackComponentClick(`npf-contribution-amount-toggle-${countryGroupId}-ONE_OFF`);
                            }, children: "Continue to checkout" }) }), _jsx(PaymentCards, {})] })] }));
}
