import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { autoUpdate, flip, FloatingPortal, offset, shift, useClick, useDismiss, useFloating, useInteractions, } from '@floating-ui/react';
import { between, from, space, textSans15, until, visuallyHidden, } from '@guardian/source/foundations';
import { Button, SvgCross } from '@guardian/source/react-components';
import { useState } from 'react';
import { InfoRound } from './InfoRound';
const buttonAndTooltipContainer = css `
	display: inline-block;
	margin-top: 2px;
`;
const tooltipAndCopyContainer = css `
	display: flex;
	cursor: pointer;
	position: relative;
	margin-top: ${space[3]}px;
`;
const tooltipContainer = css `
	position: relative;
	display: flex;

	${until.mobileMedium} {
		> div {
			left: 0 !important;
			right: 0;
			margin-left: 24px;
			margin-right: auto;
		}
	}

	${between.mobileMedium.and.mobileLandscape} {
		> div {
			left: 74px !important;
		}
	}

	${between.mobileLandscape.and.tablet} {
		> div {
			left: 94px !important;
		}
	}
`;
const tooltipContainerDisplay = (open, desktopOnly) => css `
	${!open && visuallyHidden}
	${until.desktop} {
		${desktopOnly && visuallyHidden}
	}
`;
const tooltipCss = css `
	overflow: hidden;
	${textSans15};
	background-color: #606060;
	color: white;
	padding: 12px 24px 16px 16px;
	border-radius: 4px;
	max-width: 273px;
`;
const buttonOverrides = css `
	width: fit-content;
	height: max-content;
	min-height: initial;
	border: none;

	& svg {
		width: 17px;
		height: 16px;
	}
`;
const closeButtonOverrides = css `
	width: max-content;
	height: max-content;
	margin-right: 8px;
	margin-top: 0;
	position: absolute;
	top: 0;
	right: 0;
	color: white;
	background-color: transparent;
	&:hover {
		background-color: transparent;
	}
	& svg {
		width: 16px;
		margin: 2px;
	}
`;
const copy = css `
	font-weight: bold;
	display: inline-block;
	margin-right: ${space[2]}px;

	${until.tablet} {
		margin-bottom: ${space[2]}px;
	}
`;
const arrowBottom = css `
	position: absolute;
	top: 100%;
	border-color: #606060 transparent transparent transparent;
	margin-left: -8px;
	border-width: 8px;
	border-style: solid;
	left: 49%;

	${from.mobileMedium} {
		left: 31%;
	}

	${from.mobileLandscape} {
		left: 27%;
	}

	${from.tablet} {
		left: 23%;
	}

	${from.desktop} {
		left: 10%;
	}
`;
const arrowTop = css `
	position: absolute;
	bottom: 100%;
	margin-left: -8px;
	border-width: 8px;
	border-style: solid;
	border-color: transparent transparent #606060 transparent;
	left: 49%;

	${from.mobileMedium} {
		left: 31%;
	}

	${from.mobileLandscape} {
		left: 27%;
	}

	${from.tablet} {
		left: 23%;
	}

	${from.desktop} {
		left: 10%;
	}
`;
export default function Tooltip({ promptText, buttonLabel = 'More information', children, xAxisOffset, yAxisOffset, placement, desktopOnly, }) {
    const [open, setOpen] = useState(false);
    const { x, y, refs, strategy, context } = useFloating({
        open,
        onOpenChange: setOpen,
        placement: placement ? placement : 'top',
        // Make sure the tooltip stays on the screen
        whileElementsMounted: autoUpdate,
        middleware: [
            offset({
                mainAxis: yAxisOffset ? yAxisOffset : 16,
                crossAxis: xAxisOffset ? xAxisOffset : 0,
            }),
            flip({
                // Ensure the tooltip only appears above / below reference element
                fallbackPlacements: ['top', 'bottom'],
            }),
            shift(),
        ],
    });
    // Event listeners to change the open state
    const click = useClick(context, { toggle: false });
    const dismiss = useDismiss(context);
    // Merge all the interactions into prop getters
    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
    ]);
    return (_jsxs("div", { css: tooltipAndCopyContainer, ref: refs.setReference, ...getReferenceProps(), children: [promptText && _jsx("p", { css: copy, children: promptText }), _jsxs("div", { css: buttonAndTooltipContainer, children: [_jsx("div", { children: _jsx(Button, { hideLabel: true, icon: _jsx(InfoRound, {}), priority: "tertiary", cssOverrides: buttonOverrides, children: buttonLabel }) }), _jsx(FloatingPortal, { children: _jsx("div", { role: "status", css: [tooltipContainer, tooltipContainerDisplay(open, desktopOnly)], ref: refs.setFloating, style: {
                                // Positioning styles
                                position: strategy,
                                top: y ?? 0,
                                left: x ?? 0,
                            }, ...getFloatingProps(), children: _jsxs("div", { css: tooltipCss, children: [children, _jsx(Button, { onClick: () => setOpen(false), icon: _jsx(SvgCross, { size: "xsmall" }), size: "small", hideLabel: true, priority: "secondary", cssOverrides: closeButtonOverrides, children: "Close" }), _jsx("div", { css: context.placement === 'top' ? arrowBottom : arrowTop })] }) }) })] })] }));
}
