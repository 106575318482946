import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { neutral, textSansBold14 } from '@guardian/source/foundations';
import { SecurePadlock } from 'components/svgs/securePadlock';
import { SecurePadlockCircle } from 'components/svgs/securePadlockCircle';
const darkColour = neutral[46];
const lightColour = neutral[100];
const darkColourOpacity = 1;
const lightColourOpacity = 0.9;
const theming = (theme) => css `
	color: ${theme === 'dark' ? darkColour : lightColour};
	opacity: ${theme === 'dark' ? darkColourOpacity : lightColourOpacity};
`;
const secureTransactionWithText = (align) => css `
	display: flex;
	align-items: center;
	justify-content: ${align};
`;
const secureTransactionIcon = css `
	display: flex;
	align-items: center;
`;
const padlock = css `
	margin-right: 5px;
	svg {
		display: block;
		opacity: inherit;
	}
`;
const text = css `
	${textSansBold14};
	letter-spacing: 0.01em;
	opacity: inherit;
`;
export function SecureTransactionIndicator({ align = 'center', theme = 'dark', hideText, cssOverrides, }) {
    const mainCss = hideText
        ? secureTransactionIcon
        : secureTransactionWithText(align);
    return (_jsxs("div", { css: [mainCss, theming(theme), cssOverrides], children: [_jsx("div", { css: padlock, children: hideText ? (_jsx(SecurePadlockCircle, { colour: theme === 'dark' ? darkColour : lightColour, opacity: theme === 'dark' ? darkColourOpacity : lightColourOpacity })) : (_jsx(SecurePadlock, { colour: theme === 'dark' ? darkColour : lightColour, opacity: theme === 'dark' ? darkColourOpacity : lightColourOpacity })) }), !hideText && _jsx("div", { css: text, children: "Secure transaction" })] }));
}
