import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { textSans12 } from '@guardian/source/foundations';
import { FormSection } from 'components/checkoutForm/checkoutForm';
import { StripeDisclaimer } from 'components/stripe/stripeDisclaimer';
import DirectDebitTerms from 'components/subscriptionCheckouts/directDebit/directDebitTerms';
import { DirectDebit } from 'helpers/forms/paymentMethods';
import { privacyLink, tierThreeTermsLink } from 'helpers/legal';
import { ManageMyAccountLink } from '../../pages/supporter-plus-landing/components/manageMyAccountLink';
const tierThreeTerms = css `
	${textSans12};
	color: #606060;
	p {
		margin-top: 10px;
		a {
			:visited {
				color: #606060;
			}
		}
	}
`;
const termsLink = (linkText, url) => (_jsx("a", { target: "_blank", rel: "noopener noreferrer", href: url, children: linkText }));
export default function TierThreeTerms(props) {
    const paymentFrequencyName = props.paymentFrequency === 'year' ? 'annual' : 'monthly';
    const productName = 'Digital + print';
    return (_jsxs(_Fragment, { children: [_jsx(FormSection, { children: _jsxs("div", { css: tierThreeTerms, children: [_jsxs("p", { children: ["By signing up, you are taking out a ", productName, " subscription. Your", ' ', productName, " subscription will auto-renew each", ' ', props.paymentFrequency, " unless cancelled. Your first payment will be taken on the publication date of your first Guardian Weekly magazine (as shown in the checkout) but you will start to receive your digital benefits when you sign up. Unless you cancel, subsequent ", paymentFrequencyName, " payments will be taken on this date using your chosen payment method. You can cancel your Digital + print subscription at any time before your next renewal date. If you cancel your ", productName, " subscription within 14 days of signing up, your subscription will stop immediately and we will not take the first payment from you. Cancellation of your subscription after 14 days will take effect at the end of your current", ' ', paymentFrequencyName, " payment period. To cancel go to\u00A0", ManageMyAccountLink, " or see our ", productName, ' ', termsLink('Terms', tierThreeTermsLink), "."] }), _jsxs("p", { children: ["By proceeding, you are agreeing to the ", productName, ' ', termsLink('Terms', tierThreeTermsLink), "."] }), _jsxs("p", { children: ["To find out what personal data we collect and how we use it, please visit our ", termsLink('Privacy Policy', privacyLink), "."] }), _jsx("p", { children: _jsx(StripeDisclaimer, {}) })] }) }), props.paymentMethod === DirectDebit && (_jsx(FormSection, { children: _jsx(DirectDebitTerms, { isThreeTier: true }) }))] }));
}
