import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
// ----- Imports ----- //
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { parseAppConfig } from 'helpers/globalsAndSwitches/window';
import { CountryGroup } from 'helpers/internationalisation/classes/countryGroup';
import { countryGroups } from 'helpers/internationalisation/countryGroup';
import { setUpTrackingAndConsents } from 'helpers/page/page';
import { isDetailsSupported, polyfillDetails } from 'helpers/polyfills/details';
import { initReduxForContributions } from 'helpers/redux/contributionsStore';
import { renderPage } from 'helpers/rendering/render';
import { SupporterPlusThankYou } from 'pages/supporter-plus-thank-you/supporterPlusThankYou';
import { setUpRedux } from './setup/setUpRedux';
import { threeTierCheckoutEnabled } from './setup/threeTierChecks';
import { SupporterPlusInitialLandingPage } from './twoStepPages/firstStepLanding';
import { SupporterPlusCheckout } from './twoStepPages/secondStepCheckout';
import { ThreeTierLanding } from './twoStepPages/threeTierLanding';
parseAppConfig(window.guardian);
if (!isDetailsSupported) {
    polyfillDetails();
}
setUpTrackingAndConsents();
// ----- Redux Store ----- //
const countryGroupId = CountryGroup.detect();
const store = initReduxForContributions();
setUpRedux(store);
const thankYouRoute = `/${countryGroups[countryGroupId].supportInternationalisationId}/thankyou`;
const countryIds = Object.values(countryGroups).map((group) => group.supportInternationalisationId);
// ----- ScrollToTop on Navigate: https://v5.reactrouter.com/web/guides/scroll-restoration ---- //
function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}
const commonState = store.getState().common;
export const inThreeTier = threeTierCheckoutEnabled(commonState.abParticipations, commonState.amounts);
// ----- Render ----- //
const router = () => {
    return (_jsxs(BrowserRouter, { children: [_jsx(ScrollToTop, {}), _jsx(Provider, { store: store, children: _jsx(Routes, { children: countryIds.map((countryId) => (_jsxs(_Fragment, { children: [_jsx(Route, { path: `/${countryId}/contribute/:campaignCode?`, element: inThreeTier ? (_jsx(ThreeTierLanding, { geoId: countryId })) : (_jsx(SupporterPlusInitialLandingPage, { thankYouRoute: thankYouRoute })) }), _jsx(Route, { path: `/${countryId}/contribute/checkout`, element: _jsx(SupporterPlusCheckout, { thankYouRoute: thankYouRoute }) }), _jsx(Route, { path: `/${countryId}/thankyou`, element: _jsx(SupporterPlusThankYou, {}) })] }))) }) })] }));
};
renderPage(router());
