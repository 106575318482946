import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { from, headlineBold24, space } from '@guardian/source/foundations';
import { Accordion, RadioGroup } from '@guardian/source/react-components';
import GeneralErrorMessage from 'components/generalErrorMessage/generalErrorMessage';
import { SecureTransactionIndicator } from 'components/secureTransactionIndicator/secureTransactionIndicator';
import { paymentMethodData } from './paymentMethodData';
import { AvailablePaymentMethodAccordionRow } from './paymentMethodSelectorAccordionRow';
import { ReauthenticateLink } from './reauthenticateLink';
const container = css `
	margin-top: ${space[6]}px;
`;
const header = css `
	margin-bottom: ${space[3]}px;
	${headlineBold24};
	${from.tablet} {
		font-size: 28px;
	}
`;
const paymentLegendOverrides = css `
	margin-bottom: ${space[3]}px;
`;
function PaymentMethodSelectorLegend({ cssOverrides, overrideHeadingCopy, }) {
    return (_jsxs("div", { css: css `
				display: flex;
				justify-content: space-between;
			`, children: [_jsx("legend", { id: "payment_method", children: _jsx("h2", { css: header, children: overrideHeadingCopy ?? 'Payment method' }) }), _jsx(SecureTransactionIndicator, { hideText: true, cssOverrides: cssOverrides ?? css `` })] }));
}
export function PaymentMethodSelector({ availablePaymentMethods, paymentMethod, validationError, showReauthenticateLink, onPaymentMethodEvent, overrideHeadingCopy, }) {
    if (availablePaymentMethods.length < 1) {
        return (_jsx(GeneralErrorMessage, { classModifiers: ['no-valid-payments'], errorHeading: "Payment methods are unavailable", errorReason: "all_payment_methods_unavailable" }));
    }
    return (_jsxs("div", { css: container, children: [_jsx(PaymentMethodSelectorLegend, { cssOverrides: paymentLegendOverrides, overrideHeadingCopy: overrideHeadingCopy }), _jsxs(RadioGroup, { id: "paymentMethod", role: "radiogroup", label: "Select payment method", hideLabel: true, error: validationError, children: [_jsx(Accordion, { cssOverrides: css `
						border-bottom: none;
					`, children: [
                            _jsx(_Fragment, { children: availablePaymentMethods.map((method) => (_jsx(AvailablePaymentMethodAccordionRow, { id: paymentMethodData[method].id, image: paymentMethodData[method].icon, label: paymentMethodData[method].label, name: "paymentMethod", checked: paymentMethod === method, onChange: () => onPaymentMethodEvent('select', method), accordionBody: paymentMethodData[method].accordionBody, onRender: () => onPaymentMethodEvent('render', method) }))) }),
                        ] }), showReauthenticateLink && _jsx(ReauthenticateLink, {})] })] }));
}
