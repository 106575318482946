import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { space } from '@guardian/source/foundations';
import { Ticker } from '@guardian/source-development-kitchen/react-components';
import { useEffect, useState } from 'react';
import { fetchJson } from '../../../helpers/async/fetch';
import { isCodeOrProd } from '../../../helpers/urls/url';
const containerStyle = css `
	max-width: 600px;
	margin: ${space[6]}px auto;
`;
function getTickerUrl(tickerId) {
    return isCodeOrProd() ? `/ticker/${tickerId}.json` : '/ticker.json';
}
async function getInitialTickerValues(tickerId) {
    const data = await fetchJson(getTickerUrl(tickerId), {});
    const total = Math.floor(data.total);
    const goal = Math.floor(data.goal);
    return {
        total,
        goal,
    };
}
export function TickerContainer({ tickerSettings, }) {
    const [tickerData, setTickerData] = useState();
    useEffect(() => {
        void getInitialTickerValues(tickerSettings.id).then(setTickerData);
    }, []);
    if (tickerData) {
        return (_jsx("div", { css: containerStyle, children: _jsx(Ticker, { currencySymbol: tickerSettings.currencySymbol, copy: {
                    headline: tickerSettings.copy.headline,
                }, tickerData: tickerData, tickerStylingSettings: {
                    headlineColour: tickerSettings.tickerStylingSettings.headlineColour,
                    totalColour: tickerSettings.tickerStylingSettings.totalColour,
                    goalColour: tickerSettings.tickerStylingSettings.goalColour,
                    filledProgressColour: tickerSettings.tickerStylingSettings.filledProgressColour,
                    progressBarBackgroundColour: tickerSettings.tickerStylingSettings.progressBarBackgroundColour,
                }, size: tickerSettings.size }) }));
    }
    return _jsx(_Fragment, {});
}
