import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { from, neutral, palette, space, textSansBold20, } from '@guardian/source/foundations';
import { Column, Columns, Hide } from '@guardian/source/react-components';
import { Container } from 'components/layout/container';
const mainStyles = css `
	position: absolute;
	left: 0;
	right: 0;
	color: ${neutral[100]};
`;
const headingContentContainer = css `
	height: 480px;
	${from.tablet} {
		height: 432px;
	}
	padding-top: ${space[6]}px;
	${textSansBold20}
`;
export function CheckoutHeading({ heading, children, image, withTopBorder = true, withSideBorders = true, cssOverrides, }) {
    return (_jsx("div", { css: mainStyles, children: _jsx(Container, { sideBorders: withSideBorders, topBorder: withTopBorder, borderColor: palette.brand[600], backgroundColor: palette.brand[400], children: _jsx(Columns, { collapseUntil: "desktop", children: _jsx(Column, { span: [1, 2, 5], children: _jsx("div", { css: [cssOverrides, headingContentContainer], children: _jsxs(Hide, { until: "desktop", children: [heading, children, image] }) }) }) }) }) }));
}
