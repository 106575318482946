import { combineReducers, createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { findAddressesForPostcode } from 'components/subscriptionCheckouts/address/postcodeLookup';
import { Country } from 'helpers/internationalisation/classes/country';
import { getSliceErrorsFromZodResult } from 'helpers/redux/utils/validation/errors';
import { removeError } from 'helpers/subscriptionsForms/validation';
import { resetValidation, validateForm } from '../checkoutActions';
import { addressFieldsSchema, getInitialAddressFieldsState, initialPostcodeFinderState, } from './state';
// ---- Reducers ---- //
export const { reducer: deliveryAddressReducer, fieldsSlice: deliveryAddressFieldsSlice, postcodeFinderSlice: deliveryAddressPostcodeFinderSlice, findAddresses: deliveryAddressFindAddresses, } = getAddressReducer('delivery');
export const { reducer: billingAddressReducer, fieldsSlice: billingAddressFieldsSlice, postcodeFinderSlice: billingAddressPostcodeFinderSlice, findAddresses: billingAddressFindAddresses, } = getAddressReducer('billing');
// ---- Helpers ---- //
function getAddressReducer(type) {
    const fieldsSlice = getAddressFieldsSlice(type);
    const { slice: postcodeFinderSlice, findAddresses } = getPostcodeFinderSlice(type);
    const reducer = combineReducers({
        fields: fieldsSlice.reducer,
        postcode: postcodeFinderSlice.reducer,
    });
    return { reducer, fieldsSlice, postcodeFinderSlice, findAddresses };
}
function getAddressFieldsSlice(type) {
    return createSlice({
        name: `${type}AddressFields`,
        initialState: getInitialAddressFieldsState,
        reducers: {
            setLineOne(state, action) {
                state.lineOne = action.payload;
                state.errors = removeError('lineOne', state.errors);
            },
            setLineTwo(state, action) {
                state.lineTwo = action.payload;
                state.errors = removeError('lineTwo', state.errors);
            },
            setTownCity(state, action) {
                state.city = action.payload;
                state.errors = removeError('city', state.errors);
            },
            setState(state, action) {
                state.state = action.payload;
                state.errors = removeError('state', state.errors);
                delete state.errorObject?.state;
            },
            setPostcode(state, action) {
                state.postCode = action.payload;
                state.errors = removeError('postCode', state.errors);
            },
            setCountry(state, action) {
                const country = Country.fromString(action.payload);
                if (country) {
                    state.country = country;
                    state.state = '';
                }
            },
            setFormErrors(state, action) {
                state.errors = action.payload;
            },
        },
        extraReducers: (builder) => {
            builder.addCase(validateForm, (state) => {
                const validationResult = addressFieldsSchema.safeParse(state);
                if (!validationResult.success) {
                    state.errorObject = getSliceErrorsFromZodResult(validationResult.error.format());
                }
            });
            builder.addCase(resetValidation, (state) => {
                state.errorObject = {};
            });
        },
    });
}
function getPostcodeFinderSlice(type) {
    const findAddresses = createAsyncThunk(`${type}PostcodeFinder/findAddresses`, findAddressesForPostcode);
    const slice = createSlice({
        name: `${type}PostcodeFinder`,
        initialState: initialPostcodeFinderState,
        reducers: {
            setPostcode(state, action) {
                state.postcode = action.payload;
            },
            setError(state, action) {
                state.error = action.payload;
            },
        },
        extraReducers: (builder) => {
            builder.addCase(findAddresses.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            });
            builder.addCase(findAddresses.fulfilled, (state, action) => {
                state.isLoading = false;
                state.results = action.payload;
            });
            builder.addCase(findAddresses.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
        },
    });
    return { slice, findAddresses };
}
