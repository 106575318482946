import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { focusHalo, from, space } from '@guardian/source/foundations';
import { Link } from '@guardian/source/react-components';
import { ErrorSummary } from '@guardian/source-development-kitchen/react-components';
import { useAutoFocus } from 'helpers/customHooks/useAutoFocus';
const errorContainerStyles = css `
	margin-bottom: ${space[3]}px;

	${from.tablet} {
		margin-bottom: ${space[5]}px;
	}

	${from.desktop} {
		margin-bottom: ${space[6]}px;
	}

	:focus {
		${focusHalo};
	}
`;
const errorListStyles = css `
	list-style: disc;
	margin-bottom: ${space[1]}px;
`;
export function CheckoutErrorSummary({ errorList, }) {
    const containerRef = useAutoFocus();
    if (!errorList.length) {
        return null;
    }
    return (_jsx("div", { css: errorContainerStyles, role: "alert", tabIndex: -1, ref: containerRef, "aria-live": "polite", children: _jsx(ErrorSummary, { cssOverrides: errorContainerStyles, message: "Some information is missing", context: _jsx("ul", { css: errorListStyles, children: errorList.map(({ href, message }) => (_jsx("li", { children: _jsx(Link, { "data-qm-validation": true, priority: "secondary", href: href, subdued: true, children: message }) }))) }) }) }));
}
