import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css, ThemeProvider } from '@emotion/react';
import { Radio, RadioGroup, radioThemeBrand, } from '@guardian/source/react-components';
import { useEffect, useState } from 'react';
const selectorStyles = css `
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 12px;
	font-weight: normal;
`;
export function ThankYouUserTypeSelector() {
    const [selectedUserType, setSelectedUserType] = useState('new');
    useEffect(() => {
        // ToDo: refactor this to use the query param instead of redux
    }, [selectedUserType]);
    return (_jsx(ThemeProvider, { theme: radioThemeBrand, children: _jsxs(RadioGroup, { error: "", label: "Select a user account type:", name: "thankYouUserTypeSelector", orientation: "horizontal", cssOverrides: selectorStyles, children: [_jsx(Radio
                // the default user type response from identity as a test user is "new"
                , { 
                    // the default user type response from identity as a test user is "new"
                    defaultChecked: true, label: "New", value: "new", onChange: () => setSelectedUserType('new') }), _jsx(Radio, { label: "Existing", value: "current", onChange: () => setSelectedUserType('current') })] }) }));
}
