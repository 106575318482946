import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { palette, textSans12, until } from '@guardian/source/foundations';
import { useRecaptchaV2 } from 'helpers/customHooks/useRecaptcha';
const container = css `
	${until.mobileMedium} {
		& > div {
			width: 0 !important;
			max-width: 100% !important;
		}
		iframe {
			transform: scale(0.82);
			transform-origin: top left;
		}
	}
`;
const terms = css `
	color: ${palette.neutral[46]};
	${textSans12};

	margin-top: 5px;

	a {
		color: ${palette.brand[500]};
		text-decoration: none;
	}
`;
export function Recaptcha({ id = 'robot_checkbox', onRecaptchaCompleted, onRecaptchaExpired, }) {
    useRecaptchaV2(id, onRecaptchaCompleted, onRecaptchaExpired);
    return (_jsxs(_Fragment, { children: [_jsx("div", { id: id, css: container }), _jsxs("p", { css: terms, children: ["By ticking this box, you agree to let Google perform a security check to confirm you are a human. Please refer to their", ' ', _jsx("a", { target: "_blank", rel: "noopener noreferrer", href: "https://policies.google.com/terms", children: "terms" }), ' ', "and", ' ', _jsx("a", { target: "_blank", rel: "noopener noreferrer", href: "https://policies.google.com/privacy", children: "privacy" }), ' ', "policies."] })] }));
}
