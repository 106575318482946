import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { between, from, headlineBold24, headlineBold28, palette, space, textSans17, until, } from '@guardian/source/foundations';
import { BenefitsCheckList } from './benefitsCheckList';
const containerCss = css `
	${textSans17};
	line-height: 1.15;
`;
const containerWithBackgroundCss = css `
	background-color: ${palette.neutral[97]};
	border: 1px solid ${palette.neutral[86]};
	padding: ${space[3]}px ${space[3]}px ${space[4]}px;
	border-radius: 7px;
`;
const smallMaxWidth = css `
	max-width: 250px;
	${from.desktop} {
		max-width: 280px;
	}
`;
const maxWidth = css `
	${until.mobileLandscape} {
		max-width: 15ch;
	}
	${between.tablet.and.desktop} {
		max-width: 15ch;
	}
`;
const headingCss = css `
	${headlineBold24}
	${from.tablet} {
		${headlineBold28};
		line-height: 1.15;
	}
`;
const hrCss = (margin) => css `
	border: none;
	height: 1px;
	background-color: #dcdcdc;
	margin: ${margin};
	${until.tablet} {
		margin: 14px 0;
	}
`;
export function CheckoutBenefitsList({ title, checkListData, withBackground, isCompactList, }) {
    return (_jsxs("div", { css: withBackground
            ? [containerCss, containerWithBackgroundCss]
            : [containerCss], children: [_jsx("h2", { css: withBackground ? [headingCss, maxWidth] : [headingCss, smallMaxWidth], children: _jsx("span", { children: title }) }), _jsx("hr", { css: hrCss(`${space[4]}px 0`) }), _jsx(BenefitsCheckList, { benefitsCheckListData: checkListData, style: isCompactList ? 'compact' : 'standard', iconColor: palette.brand[500] }), !withBackground && _jsx("hr", { css: hrCss(`${space[5]}px 0 ${space[4]}px`) })] }));
}
