import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { between, from, space } from '@guardian/source/foundations';
import { ThreeTierCard } from './threeTierCard';
const container = (cardCount) => css `
	display: flex;
	flex-direction: column;
	gap: ${space[3]}px;

	> * {
		flex-basis: ${100 / cardCount}%;
	}

	${between.tablet.and.desktop} {
		margin: 0 auto;
		max-width: 340px;
	}

	${from.desktop} {
		gap: ${space[5]}px;
		flex-direction: row;
	}
`;
const cardIndexToTier = (index) => {
    switch (index) {
        case 1:
            return 2;
        case 2:
            return 3;
        case 0:
        default:
            return 1;
    }
};
export function ThreeTierCards({ cardsContent, currencyId, countryGroupId, paymentFrequency, abParticipations, }) {
    const haveRecommendedAndSelectedCards = cardsContent.filter((card) => card.isRecommended || card.isUserSelected)
        .length > 1;
    let promoCount = 0;
    return (_jsx("div", { css: container(cardsContent.length), role: "tabpanel", id: `${paymentFrequency}-tab`, "aria-labelledby": `${paymentFrequency}`, children: cardsContent.map((cardContent, cardIndex) => {
            if (cardContent.promotion) {
                promoCount++;
            }
            return (_jsx(ThreeTierCard, { cardTier: cardIndexToTier(cardIndex), promoCount: promoCount, ...cardContent, isRecommendedSubdued: haveRecommendedAndSelectedCards, currencyId: currencyId, countryGroupId: countryGroupId, paymentFrequency: paymentFrequency, ctaCopy: cardContent.ctaCopy, abParticipations: abParticipations }, `threeTierCard${cardIndex}`));
        }) }));
}
