import { getContributionType } from 'helpers/redux/checkout/product/selectors/productType';
export function getOtherAmountErrors(state) {
    const constributionType = getContributionType(state);
    const { errors, selectedAmounts } = state.page.checkoutForm.product;
    if (selectedAmounts[constributionType] !== 'other') {
        return [];
    }
    if (errors.otherAmount?.length) {
        return errors.otherAmount;
    }
    return;
}
