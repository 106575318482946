import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { neutral, space } from '@guardian/source/foundations';
import { checkListData } from 'components/checkoutBenefits/checkoutBenefitsListData';
import { getAmount } from 'helpers/contributions';
import { currencies } from 'helpers/internationalisation/currency';
import { productLegal } from 'helpers/legalCopy';
import { isSupporterPlusFromState } from 'helpers/redux/checkout/product/selectors/isSupporterPlus';
import { getContributionType } from 'helpers/redux/checkout/product/selectors/productType';
import { useContributionsSelector } from 'helpers/redux/storeHooks';
import { trackComponentClick } from 'helpers/tracking/behaviour';
const containerSummaryTsCs = css `
	border-radius: ${space[2]}px;
	background-color: ${neutral[97]};
	padding: ${space[3]}px;
`;
export function getTermsStartDateTier3(startDateTier3) {
    return (_jsxs(_Fragment, { children: [_jsx("li", { children: "Your digital benefits will start today." }), _jsxs("li", { children: ["Your Guardian Weekly subscription will start on ", startDateTier3, ". Please allow 1 to 7 days after your start date for your magazine to arrive, depending on national post services."] })] }));
}
export function getTermsConditions(countryGroupId, contributionType, productId, promotion) {
    if (contributionType === 'ONE_OFF') {
        return;
    }
    const period = contributionType === 'MONTHLY' ? 'month' : 'year';
    const isSupporterPlus = productId === 'SupporterPlus';
    const isTier3 = productId === 'TierThree';
    if (isSupporterPlus || isTier3) {
        return (_jsxs("div", { css: containerSummaryTsCs, children: [promotion && (_jsxs("p", { children: ["You\u2019ll pay", ' ', productLegal(countryGroupId, contributionType, '/', productId, promotion), ' ', "afterwards unless you cancel. Offer only available to new subscribers who do not have an existing subscription with the Guardian."] })), isSupporterPlus && (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Auto renews every ", period, " until you cancel."] }), _jsx("p", { children: "Cancel or change your support anytime. If you cancel within the first 14 days, you will receive a full refund." })] })), isTier3 && _jsxs("p", { children: ["Auto renews every ", period, ". Cancel anytime."] })] }));
    }
    return (_jsxs("div", { css: containerSummaryTsCs, children: [_jsxs("p", { children: ["Auto renews every ", period, " until you cancel."] }), _jsx("p", { children: "Cancel or change your support anytime." })] }));
}
export function ContributionsOrderSummaryContainer({ renderOrderSummary, promotion, }) {
    const contributionType = useContributionsSelector(getContributionType);
    const { currencyId, countryGroupId } = useContributionsSelector((state) => state.common.internationalisation);
    const currency = currencies[currencyId];
    const isSupporterPlus = useContributionsSelector(isSupporterPlusFromState);
    const amount = useContributionsSelector((state) => getAmount(state.page.checkoutForm.product.selectedAmounts, state.page.checkoutForm.product.otherAmounts, contributionType));
    const checklist = contributionType === 'ONE_OFF'
        ? []
        : checkListData({
            higherTier: isSupporterPlus,
            countryGroupId,
        });
    function onCheckListToggle(isOpen) {
        trackComponentClick(`contribution-order-summary-${isOpen ? 'opened' : 'closed'}`);
    }
    let description;
    let heading;
    let product;
    if (contributionType === 'ONE_OFF') {
        heading = 'Your support';
        description = 'One-time support';
        product = 'Contribution';
    }
    else if (isSupporterPlus) {
        description = 'All-access digital';
        product = 'SupporterPlus';
    }
    else {
        description = 'Support';
        product = 'Contribution';
    }
    const paymentFrequency = contributionType === 'MONTHLY'
        ? 'month'
        : contributionType === 'ANNUAL'
            ? 'year'
            : '';
    return renderOrderSummary({
        description,
        amount: amount,
        promotion,
        currency,
        paymentFrequency,
        enableCheckList: contributionType !== 'ONE_OFF',
        checkListData: checklist,
        onCheckListToggle,
        heading,
        tsAndCs: getTermsConditions(countryGroupId, contributionType, product, promotion),
    });
}
