import * as abTest from 'helpers/abTests/abtest';
import { getAmountsTestVariant } from 'helpers/abTests/abtest';
import { getSettings } from 'helpers/globalsAndSwitches/globals';
import { Country } from 'helpers/internationalisation/classes/country';
import { CountryGroup } from 'helpers/internationalisation/classes/countryGroup';
import { detect as detectCurrency } from 'helpers/internationalisation/currency';
import { getCampaign, getReferrerAcquisitionData, } from 'helpers/tracking/acquisitions';
import { getAllQueryParamsWithExclusions } from 'helpers/urls/url';
// Creates the initial state for the common reducer.
function buildInitialState(abParticipations, countryGroupId, countryId, currencyId, settings, acquisitionData, amounts) {
    const excludedParameters = ['REFPVID', 'INTCMP', 'acquisitionData'];
    const otherQueryParams = getAllQueryParamsWithExclusions(excludedParameters);
    const internationalisation = {
        countryGroupId,
        countryId,
        currencyId,
        useLocalCurrency: false,
        defaultCurrency: currencyId,
    };
    return {
        campaign: getCampaign(acquisitionData),
        referrerAcquisitionData: acquisitionData,
        otherQueryParams,
        internationalisation,
        abParticipations,
        settings,
        amounts,
    };
}
export function getInitialState() {
    const countryId = Country.detect();
    const countryGroupId = CountryGroup.detect();
    const currencyId = detectCurrency(countryGroupId);
    const settings = getSettings();
    const { selectedAmountsVariant, amountsParticipation } = getAmountsTestVariant(countryId, countryGroupId, settings);
    const abtestInitalizerData = {
        countryId,
        countryGroupId,
        selectedAmountsVariant,
    };
    const participations = abTest.init(abtestInitalizerData);
    const participationsWithAmountsTest = {
        ...participations,
        ...amountsParticipation,
    };
    const acquisitionData = getReferrerAcquisitionData();
    return buildInitialState(participationsWithAmountsTest, countryGroupId, countryId, currencyId, settings, acquisitionData, selectedAmountsVariant);
}
