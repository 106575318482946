import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
function MarketingCopy({ contributionType, isTier3, }) {
    return (_jsxs("span", { children: [contributionType === 'ONE_OFF'
                ? 'Thank you for your contribution. We’ll be in touch to bring you closer to our journalism. You can amend your email preferences at any time via '
                : isTier3
                    ? 'You can adjust your email preferences and opt out anytime via '
                    : 'Adjust your email preferences at any time via ', _jsx("a", { href: "https://manage.theguardian.com", children: "your account" }), "."] }));
}
const getSubHeadingCopy = (amountIsAboveThreshold, contributionType, isTier3, isSignedIn, identityUserType) => {
    const recurringCopy = (amountIsAboveThreshold) => {
        const signedInAboveThreshold = (_jsx("span", { css: css `
					font-weight: bold;
				`, children: `You have unlocked your exclusive supporter extras – we hope you	enjoy them.${' '}` }));
        const signedInBelowThreshold = (_jsx("span", { children: `Look out for your exclusive newsletter from our supporter editor.
						We’ll also be in touch with other great ways to get closer to
						Guardian journalism.${' '}` }));
        const tier3HeadingCopy = (_jsx("span", { children: `You'll receive a confirmation email containing everything you need to know about your subscription, including additional emails on how to make the most of your subscription.${' '}` }));
        return {
            isSignedIn: isTier3 ? (_jsx(_Fragment, { children: tier3HeadingCopy })) : amountIsAboveThreshold ? (_jsxs(_Fragment, { children: [signedInAboveThreshold, signedInBelowThreshold] })) : (_jsx(_Fragment, { children: signedInBelowThreshold })),
            notSignedIn: _jsx(_Fragment, { children: isTier3 ? tier3HeadingCopy : signedInBelowThreshold }),
        };
    };
    return (contributionType !== 'ONE_OFF' &&
        recurringCopy(amountIsAboveThreshold)[identityUserType === 'current' || isSignedIn
            ? 'isSignedIn'
            : 'notSignedIn']);
};
function Subheading({ contributionType, amountIsAboveThreshold, isTier3, isSignedIn, identityUserType, }) {
    const subheadingCopy = getSubHeadingCopy(amountIsAboveThreshold, contributionType, isTier3, isSignedIn, identityUserType);
    return (_jsxs(_Fragment, { children: [subheadingCopy, _jsx(MarketingCopy, { contributionType: contributionType, isTier3: isTier3 }), identityUserType !== 'current' &&
                !isTier3 &&
                contributionType !== 'ONE_OFF' && (_jsxs("span", { css: css `
							font-weight: bold;
						`, children: [' ', "In the meantime, please sign in to get the best supporter experience."] }))] }));
}
export function OfferHeading() {
    return (_jsxs(_Fragment, { children: [_jsx("div", { css: css `
					font-weight: bold;
				`, children: "We will email you within 24 hours with a unique promo code and instructions for how to redeem your free book from Tertulia." }), _jsxs("span", { children: ["To preview the list of books, click", ' ', _jsx("a", { href: "https://tertulia.com/editorial-list/guardian-editors-gift-books-for-supporters", children: "here." }), ' ', "(Remember, you will not be able to claim your free book until you receive your promo code.)"] })] }));
}
export default Subheading;
