import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { neutral, textSans12, until } from '@guardian/source/foundations';
const textStyles = (theme) => css `
	${textSans12};
	color: #606060;

	${until.tablet} {
		color: ${theme === 'dark' ? '#606060' : neutral[100]};
	}
`;
export function FinePrint({ mobileTheme, cssOverrides, children, }) {
    return _jsx("div", { css: [textStyles(mobileTheme), cssOverrides], children: children });
}
