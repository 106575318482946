import { getAmount } from 'helpers/contributions';
import { getContributionType } from './productType';
export function getUserSelectedAmount(state) {
    const contributionType = getContributionType(state);
    const { coverTransactionCost, selectedAmounts, otherAmounts } = state.page.checkoutForm.product;
    return getAmount(selectedAmounts, otherAmounts, contributionType, coverTransactionCost);
}
export function getUserSelectedOtherAmount(state) {
    const contributionType = getContributionType(state);
    const { selectedAmounts } = state.page.checkoutForm.product;
    const priceCardAmountSelected = selectedAmounts[contributionType];
    return priceCardAmountSelected;
}
