import { createSlice } from '@reduxjs/toolkit';
import { CountryGroup } from 'helpers/internationalisation/classes/countryGroup';
import { fromCountryGroupId } from 'helpers/internationalisation/currency';
import { initialCommonState } from './state';
function getInternationalisationFromCountry(countryId, internationalisation) {
    const countryGroupId = CountryGroup.fromCountry(countryId) ?? internationalisation.countryGroupId;
    const currencyId = fromCountryGroupId(countryGroupId);
    return {
        countryGroupId,
        currencyId,
        countryId,
    };
}
export const commonSlice = createSlice({
    name: 'common',
    initialState: initialCommonState,
    reducers: {
        setInitialCommonState(state, action) {
            const { campaign, referrerAcquisitionData, otherQueryParams, internationalisation, abParticipations, settings, amounts, } = action.payload;
            return {
                ...state,
                campaign,
                referrerAcquisitionData,
                otherQueryParams,
                internationalisation,
                abParticipations,
                settings,
                amounts,
            };
        },
        setCountryInternationalisation(state, action) {
            state.internationalisation = {
                ...state.internationalisation,
                ...getInternationalisationFromCountry(action.payload, state.internationalisation),
            };
        },
        setContributionTypes(state, action) {
            state.settings.contributionTypes = action.payload;
        },
        setCurrencyId(state) {
            state.internationalisation.currencyId =
                state.internationalisation.defaultCurrency;
        },
    },
});
export const commonReducer = commonSlice.reducer;
