import { z } from 'zod';
import { getGlobal } from 'helpers/globalsAndSwitches/globals';
import { Country } from 'helpers/internationalisation/classes/country';
import { getCurrency } from 'helpers/productPrice/productPrices';
import { formatMachineDate } from 'helpers/utilities/dateConversions';
const maxOneDecimalPlaceRegex = /^\d+\.?\d{0,2}$/;
export const otherAmountSchema = z.object({
    amount: z
        .string({ invalid_type_error: 'Please enter an amount' })
        .regex(maxOneDecimalPlaceRegex, { message: 'Please enter a valid amount' }),
});
const currency = getCurrency(Country.detect());
export const initialProductState = {
    productType: 'NoProduct',
    productOption: 'NoProductOptions',
    fulfilmentOption: 'NoFulfilmentOptions',
    billingPeriod: 'Monthly',
    productPrices: getGlobal('productPrices') ?? {},
    allProductPrices: window.guardian.allProductPrices,
    selectedAmounts: {
        ONE_OFF: 0,
        MONTHLY: 0,
        ANNUAL: 0,
    },
    otherAmounts: {
        ONE_OFF: {
            amount: null,
        },
        MONTHLY: {
            amount: null,
        },
        ANNUAL: {
            amount: null,
        },
    },
    currency,
    orderIsAGift: getGlobal('orderIsAGift') ?? false,
    startDate: formatMachineDate(new Date()),
    errors: {},
};
