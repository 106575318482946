import { useCallback, useEffect, useState } from 'react';
import { validateForm } from 'helpers/redux/checkout/checkoutActions';
import { confirmAccountDetails } from 'helpers/redux/checkout/payment/directDebit/thunks';
import { validateOtherAmount } from 'helpers/redux/checkout/product/actions';
import { contributionsFormHasErrors } from 'helpers/redux/selectors/formValidation/contributionFormValidation';
import { useContributionsDispatch, useContributionsSelector, } from 'helpers/redux/storeHooks';
import { paymentWaiting } from 'pages/supporter-plus-landing/setup/legacyActionCreators';
function useValidation(paymentHandler, validationActionCreator, dispatchPaymentWaiting = true) {
    const [clickEvent, setClickEvent] = useState(null);
    const dispatch = useContributionsDispatch();
    const errorsPreventSubmission = useContributionsSelector(contributionsFormHasErrors);
    const validateAndPay = useCallback(function validateAndPay(event) {
        event.preventDefault();
        dispatch(validationActionCreator());
        setClickEvent(event);
    }, [dispatch]);
    useEffect(() => {
        if (errorsPreventSubmission) {
            setClickEvent(null);
            return;
        }
        if (clickEvent) {
            dispatchPaymentWaiting && dispatch(paymentWaiting(true));
            paymentHandler(clickEvent);
        }
    }, [clickEvent, errorsPreventSubmission]);
    return validateAndPay;
}
/**
 * A hook to wrap any payment handler function.
 * Validates the form, and will only run the handler if the form is valid.
 */
export function useFormValidation(paymentHandler, dispatchPaymentWaiting = true) {
    const { paymentMethod } = useContributionsSelector((state) => state.page.checkoutForm.payment);
    const validateAndPay = useValidation(paymentHandler, () => validateForm(paymentMethod.name), dispatchPaymentWaiting);
    return validateAndPay;
}
/**
 * A hook to wrap any payment handler function.
 * Validates the other amount field *only*, and will only run the handler if the form is valid.
 */
export function useOtherAmountValidation(paymentHandler, dispatchPaymentWaiting = true) {
    const validateAndPay = useValidation(paymentHandler, validateOtherAmount, dispatchPaymentWaiting);
    return validateAndPay;
}
export function useDirectDebitValidation(paymentHandler, dispatchPaymentWaiting = true) {
    const [clickEvent, setClickEvent] = useState(null);
    const dispatch = useContributionsDispatch();
    const errorsPreventSubmission = useContributionsSelector(contributionsFormHasErrors);
    const { phase } = useContributionsSelector((state) => state.page.checkoutForm.payment.directDebit);
    const validateAndPay = useCallback(function validateAndPay(event) {
        dispatchPaymentWaiting && dispatch(paymentWaiting(true));
        event.preventDefault();
        dispatch(validateForm('DirectDebit'));
        void dispatch(confirmAccountDetails());
        setClickEvent(event);
    }, [dispatch]);
    useEffect(() => {
        if (errorsPreventSubmission) {
            dispatch(paymentWaiting(false));
            setClickEvent(null);
            return;
        }
        if (clickEvent && phase === 'confirmation') {
            paymentHandler(clickEvent);
        }
    }, [clickEvent, errorsPreventSubmission, phase]);
    return validateAndPay;
}
