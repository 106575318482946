import { getAllErrorsForContributions } from 'helpers/redux/selectors/formValidation/contributionFormValidation';
import { useContributionsSelector } from 'helpers/redux/storeHooks';
function createErrorList(errors) {
    return Object.entries(errors).flatMap(([fieldName, errorList]) => {
        if (!errorList) {
            return [];
        }
        return errorList.map((message) => ({ href: `#${fieldName}`, message }));
    });
}
export function CheckoutErrorSummaryContainer({ renderSummary, }) {
    const errors = useContributionsSelector(getAllErrorsForContributions);
    return renderSummary({
        errorList: createErrorList(errors),
    });
}
