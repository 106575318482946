import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { palette, space, until } from '@guardian/source/foundations';
import { Divider } from '@guardian/source-development-kitchen/react-components';
const looseSpacing = css `
	margin: 40px 0 ${space[6]}px;
`;
const tightSpacing = css `
	margin: ${space[4]}px 0;
`;
const lightColour = css `
	${until.tablet} {
		background-color: ${palette.brand[600]};
	}
`;
const divider = (spacing, theme) => css `
	max-width: 100%;
	${spacing === 'tight' ? tightSpacing : looseSpacing};
	${theme === 'light' ? lightColour : ''};
`;
export function CheckoutDivider({ spacing, mobileTheme = 'dark', }) {
    return _jsx(Divider, { size: "full", cssOverrides: divider(spacing, mobileTheme) });
}
