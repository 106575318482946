import { getAmount } from 'helpers/contributions';
import { getContributionType } from 'helpers/redux/checkout/product/selectors/productType';
// Used when sending data to Quantum Metric from Contributions Checkout
function getContributionCartValueData(contributionsState) {
    const pageState = contributionsState.page;
    const contributionType = getContributionType(contributionsState);
    const { coverTransactionCost, otherAmounts, selectedAmounts } = pageState.checkoutForm.product;
    const contributionAmount = getAmount(selectedAmounts, otherAmounts, contributionType, coverTransactionCost);
    const contributionCurrency = pageState.checkoutForm.product.currency;
    return {
        contributionAmount,
        contributionType,
        contributionCurrency,
    };
}
export { getContributionCartValueData };
