import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { isSwitchOn } from 'helpers/globalsAndSwitches/globals';
import { AUDCountries, GBPCountries, UnitedStates, } from '../internationalisation/countryGroup';
export function countdownSwitchOn() {
    const isOn = isSwitchOn('featureSwitches.enableCampaignCountdown');
    return isOn;
}
const campaigns = {
    usEoy2024: {
        isEligible: (countryGroupId) => countryGroupId === UnitedStates,
        enableSingleContributions: false,
        countdownSettings: [
            {
                label: 'This Giving Tuesday, give to the Guardian',
                countdownStartInMillis: Date.parse('Nov 27, 2024 00:01:00'),
                countdownDeadlineInMillis: Date.parse('Dec 03, 2024 23:59:59'),
                theme: {
                    backgroundColor: '#ab0613',
                    foregroundColor: '#ffffff',
                },
            },
            {
                label: "It's not too late to give to the Guardian",
                countdownStartInMillis: Date.parse('Dec 04, 2024 00:01:00'),
                countdownDeadlineInMillis: Date.parse('Dec 05, 2024 00:01:00'),
                theme: {
                    backgroundColor: '#ab0613',
                    foregroundColor: '#ffffff',
                },
            },
            {
                label: 'Last chance to support us this year',
                countdownStartInMillis: Date.parse('Dec 20, 2024 00:01:00'),
                countdownDeadlineInMillis: Date.parse('Dec 31, 2024 23:59:59'),
                theme: {
                    backgroundColor: '#ab0613',
                    foregroundColor: '#ffffff',
                },
            },
        ],
        copy: {
            headingFragment: _jsx(_Fragment, { children: "Protect " }),
            subheading: (_jsxs(_Fragment, { children: ["We're not owned by a billionaire or shareholders: our fiercely independent journalism is funded by our readers. Monthly giving makes the most impact. ", _jsx("strong", { children: "You can cancel anytime." })] })),
            oneTimeHeading: _jsx(_Fragment, { children: "Choose your gift amount" }),
        },
        tickerSettings: {
            currencySymbol: '$',
            copy: {},
            tickerStylingSettings: {
                headlineColour: '#ffffff',
                totalColour: '#ffffff',
                goalColour: '#ffffff',
                filledProgressColour: '#ffffff',
                progressBarBackgroundColour: 'rgba(250, 250, 250, 0.3)',
            },
            size: 'large',
            id: 'US',
        },
    },
    ausEoy2024: {
        isEligible: (countryGroupId) => countryGroupId === AUDCountries,
        enableSingleContributions: false,
        countdownSettings: [],
        copy: {
            headingFragment: _jsx(_Fragment, { children: "Support " }),
            subheading: (_jsxs(_Fragment, { children: ["We're not owned by a billionaire or shareholders - our readers support us. Choose to join with one of the options below.", _jsx("strong", { children: "Cancel anytime." })] })),
            oneTimeHeading: _jsx(_Fragment, { children: "Choose your gift amount" }),
        },
        tickerSettings: {
            currencySymbol: '$',
            copy: {},
            tickerStylingSettings: {
                headlineColour: '#000000',
                totalColour: '#FBBCC7',
                goalColour: '#FFFFFF',
                filledProgressColour: '#FBBCC7',
                progressBarBackgroundColour: 'rgba(100, 183, 196, 0.3)',
            },
            size: 'large',
            id: 'AU',
        },
    },
    ukBlackFriday2024: {
        isEligible: (countryGroupId, promoCode) => countryGroupId === GBPCountries &&
            promoCode === 'BLACK_FRIDAY_DISCOUNT_2024',
        enableSingleContributions: false,
        countdownSettings: [
            {
                label: 'Last chance to claim your Black Friday offer',
                countdownStartInMillis: Date.parse('Nov 29, 2024 00:00:00'),
                countdownDeadlineInMillis: Date.parse('Dec 02, 2024 23:59:59'),
                theme: {
                    backgroundColor: '#1e3e72',
                    foregroundColor: '#ffffff',
                },
            },
        ],
        copy: {
            headingFragment: _jsx(_Fragment, { children: "This Black Friday, why not support " }),
            subheading: (_jsxs(_Fragment, { children: ["We're not owned by a billionaire or shareholders - our readers support us. Choose to join with one of the options below.", _jsx("strong", { children: "Cancel anytime." })] })),
            oneTimeHeading: _jsx(_Fragment, { children: "Choose your gift amount" }),
            punctuation: _jsx(_Fragment, { children: "?" }),
        },
    },
};
const forceCampaign = (campaignId) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('forceCampaign') === campaignId;
};
export function getCampaignSettings(countryGroupId, promoCode) {
    for (const campaignId in campaigns) {
        const isEligible = isCampaignEnabled(campaignId) &&
            campaigns[campaignId].isEligible(countryGroupId, promoCode);
        if (isEligible || forceCampaign(campaignId)) {
            return campaigns[campaignId];
        }
    }
    return null;
}
function isCampaignEnabled(campaignId) {
    const { campaignSwitches } = window.guardian.settings.switches;
    return (window.location.hash ===
        `#settings.switches.campaignSwitches.${campaignId}=On` ||
        campaignSwitches[campaignId] === 'On');
}
